//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import mixin from '../components/minxin'
import upload from '@/views/fileStream/documentManage/upload'
import { documentBillEdit, documentBillAdd, documentBillDetail } from '@/api/modular/fileStream/documentManage'
export default {
  mixins: [mixin],
  components: {
    upload,
  },
  data() {
    return {
      moment,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      form: this.$form.createForm(this),
      fileIds: [],
      myform: {},
    }
  },
  mounted() {
    const myData = { ...this.$store.state.mobile.addBillMess }
    const keys = Object.keys(myData)
    const id = this.$route.query.id
    this.form.setFieldsValue(myData)
    if (keys.length === 0 && id) {
      this.get_info(id)
    }
  },
  methods: {
    showFormTable() {
      const formValue = this.delEmptKey(this.form.getFieldsValue())
      this.$store.commit('mobile/cacheBillMess', formValue)
      this.$router.push({ name: 'mobileShowForm', query: { ...formValue } })
    },
    get_info(id) {
      this.form_id = id
      documentBillDetail({ id }).then((res) => {
        const {
          acceptTime,
          name,
          urgencyDegree,
          agency,
          secrecyDegree,
          deviseOpinion,
          draftId,
          approvalWay,
          // title,
          fileType,
          documentNodeList,
          documentFileList,
          content,
          summary,
          documentNumber,
        } = res.data
        const form = {
          swsj: acceptTime,
          biaoti: name,
          jjcd: String(urgencyDegree),
          lwjg: agency,
          miji: String(secrecyDegree),
          nbyj: deviseOpinion,
          draftId,
          summary,
          spfs: '1' || String(approvalWay),
          // wjbt: title,
          xzfn: String(fileType),
          nodes: documentNodeList,
          fileIds: documentFileList,
          content,
          documentNumber,
        }
        //
        this.fileIds = documentFileList
        this.form.setFieldsValue(form)
      })
    },
    delEmptKey(obj) {
      const objCopy = { ...obj }
      const keys = Object.keys(objCopy)
      for (const index of keys) {
        if (!objCopy[index]) delete objCopy[index]
      }
      return objCopy
    },
    // 處理日期
    disabledDateHandler(current) {
      return current < moment().subtract(1, 'days').endOf('day')
    },
    handleSubmit(isCreateApp) {
      const { content, fileIds } = this
      let func = documentBillAdd
      const id = this.form_id
      if (id) {
        func = documentBillEdit
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          const obj = {
            acceptTime: values.swsj ? moment(values.swsj).format('YYYY-MM-DD') : '',
            name: values.biaoti,
            urgencyDegree: Number(values.jjcd),
            agency: values.lwjg,
            secrecyDegree: Number(values.miji),
            deviseOpinion: values.nbyj,
            approvalWay: Number(values.spfs),
            // title: values.wjbt,
            fileType: Number(values.xzfn),
            fileIds,
            summary: values.summary,
            content,
            documentType: 1,
          }
          if (id) {
            obj.id = id
          }
          func(obj).then((res) => {
            if (!res.success) {
              return
            }
            this.$message.success('登記成功')
            this.form.resetFields()
            this.$store.commit('mobile/cacheBillMess', {})
            if (id) {
              this.$router.go(-1)
            } else if (isCreateApp) {
              this.$router.push({ name: 'mobileCreateApproval', query: { id: res.data } })
            } else {
              this.$emit('changeTab')
            }
          })
        }
      })
    },
  },
}
