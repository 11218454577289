//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { initWx } from '../components/minxin'
import mobileAddBill from './addBill.vue'
import mobileTableList from './components/list'
export default {
    mixins: [initWx],
    props: {
        fileIds: {
            type: Array,
            default() {
                return []
            },
        },
    },
    components: {
        mobileAddBill,
        mobileTableList,
    },
    data() {
        return {
            active: 1,
            tabs: [
                {
                    title: '新增',
                    id: 1,
                },
                {
                    title: '全部',
                    id: 2,
                },
                {
                    title: '未完成',
                    id: 3,
                },
                {
                    title: '已完成',
                    id: 4,
                },
            ],
        }
    },
    beforeRouteLeave(to, from, next) {
        if (to.name !== 'mobileShowForm') {
            this.$store.commit('mobile/cacheBillMess', {})
        }
        next()
    },
    methods: {
        handleChange(evt, todo) {
            this.active = evt
            this.setSwiper(evt)
            this.$refs.mobileBillContent.scrollTop = 0
            if (todo === 'refresh') this.$refs.mobileTable[evt - 1].refresh()
        },
        setSwiper(index) {
            this.$refs.swipe.swipeTo(index)
        },
    },
}
