//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { delDocumentBill, documentBillPage } from '@/api/modular/fileStream/documentManage'
import item from '../../components/item'
import searchBox from '../../components/searchBox'
import { isFalse } from '../config'
export default {
    components: {
        item,
        searchBox,
    },
    props: {
        approvalTab: {
            type: Number,
            default: -1,
        },
    },
    watch: {
        approvalTab: {
            handler(val) {
                if (!isFalse(val)) {
                    this.queryParam.approvalTab = val
                    this.$refs.table && this.$refs.table.refresh(true)
                }
            },
            immediate: true,
        },
    },
    data() {
        return {
            loading: false,
            finished: false,
            queryParam: { approvalTab: 1, documentType: 1 },
            pageNo: 1,
            billList: [],
            pageSize: 10,
        }
    },
    mounted() {
        this.getBillList()
    },
    methods: {
        onLoad() {
            this.loading = true
            this.pageNo++
            this.getBillList()
        },
        refresh() {
            this.pageNo = 1
            this.billList = []
            this.pageSize = 10
            this.getBillList()
        },
        singleDelete({ id }) {
            delDocumentBill([
                {
                    id,
                },
            ])
                .then((res) => {
                    if (res.code === 200) {
                        this.$message.success('刪除成功')
                        this.refresh()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        deal({ id }) {
            this.$router.push({ name: 'showMobileApproval', query: { id, todo: 'dealWith' } })
        },
        edit(record) {
            this.$router.push({ name: 'mobileBillEdit', query: { id: record.id } })
        },
        addApproval({ id }) {
            this.$router.push({ name: 'mobileCreateApproval', query: { id } })
        },
        onSearch() {
            this.refresh()
        },
        see(record) {
            this.$router.push({ name: 'showMobileApproval', query: { id: record.id } })
        },
        getBillList() {
            const formatDraftId = (id) => {
                if (!id) return []
                return JSON.parse(id)
            }
            documentBillPage(
                Object.assign(
                    {
                        pageNo: this.pageNo,
                        pageSize: this.pageSize,
                    },
                    this.queryParam
                )
            )
                .then((res) => {
                    const data = { ...res.data }
                    data.rows = data.rows.map((ele) => {
                        ele.draftId = formatDraftId(ele.draftId)
                        return ele
                    })
                    this.finished = data.rows.length < this.pageSize
                    this.billList = [...this.billList, ...data.rows]
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
}
